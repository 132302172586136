<template>
  <main>
    <CForm>
      <CCard>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Report/MemberLevelChangeTrend') }}</h5>
            </CCol>
            <CCol col="6" class="text-md-right">
              <CButtonGroup class="mr-2">
                <CButton v-for="Type in ViewTypeOptions" :key="Type.value" size="sm" :color="(ViewType === Type.value ? 'danger' : 'light')"  @click="GetList(Type.value)">{{ Type.label }}</CButton>
              </CButtonGroup>
              <CDropdown size="sm" color="danger" placement="bottom-start" class="d-inline-block">
                <template #toggler-content>
                  <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Organization.MemberAction') }}
                </template>
                <CDropdownItem @click="Print()">
                  {{ $t('Report.PrintCSV') }}
                </CDropdownItem>
              </CDropdown>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto px-2">
          <CRow class="text-nowrap">
            <CCol col="12">
              <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
                <CIcon name="cil-filter"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block"
                       @click="OpenFilter('CreateTime')">
                {{ $t('Global.CreateTime') }}: {{
                  SearchFilter.StartTime && SearchFilter.EndTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD') + ' ~ ' + $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD') : '全部區間'
                }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
                <CIcon size="sm" name="cil-x"/>
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto">
          <CRow class="justify-content-center">
            <CCol col="12" lg="8">
              <CChartLine
                :datasets="ChartDatasets"
                :labels="ChartLabels"
                :options="ChartOptions"
              />
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
            id="MemberRegisterDailyList"
            :items="Reporting"
            :fields="Field"
            :loading="Loading"
            :noItemsView="noItemsView"
            addTableClasses="small text-nowrap"
            responsive
            hover
          >
            <template #CreateTime="{item}">
              <td>
                {{ ViewType === 'Monthly' ? $dayjs(item._id).format('YYYY-MM') : item._id }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CForm>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'CreateTime'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.CreateTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.TimeRange" is-range
                         @input="SearchFilter.StartTime = SearchFilter.TimeRange.start;SearchFilter.EndTime = SearchFilter.TimeRange.end">
            <template v-slot="{ inputValue, inputEvents }">
              <CRow>
                <CCol col="12">
                  <CInput :placeholder="$t('Logistics.DeliverySettings.SelectStartDate')" :value="inputValue.start"
                          v-on="inputEvents.start">
                    <template #append-content>
                      <CIcon name="cil-calendar"/>
                    </template>
                  </CInput>
                </CCol>
                <CCol col="12">
                  <CInput :placeholder="$t('Logistics.DeliverySettings.SelectEndDate')" :value="inputValue.end"
                          v-on="inputEvents.end">
                    <template #append-content>
                      <CIcon name="cil-calendar"/>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="GetList()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "會員等級變化趨勢"
}
}
</route>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
export default {
  name: 'MemberRegisterDaily',
  layout: 'manage',
  components: {
    CChartLine
  },
  data() {
    return {
      Reporting: [],
      DateRange: [],
      MemberLevelList: [],
      Tags: [],
      SearchFilter: {
        Email: [],
        Class: '',
        Name: '',
        Country: '',
        Mobile: '',
        Tags: [],
        Level: [],
        TotalRealAmountMin: '',
        TotalRealAmountMax: '',
        WalletBonusMin: '',
        WalletBonusMax: '',
        BirthdayMonth: '',
        StartTime: this.$dayjs().subtract(2, 'month').startOf('month').unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        TimeRange: {
          start: this.$dayjs().subtract(2, 'month').startOf('month').unix() * 1000,
          end: this.$dayjs().unix() * 1000
        },
        City: '',
        Area: '',
        Address: ''
      },
      Features: [],
      NotifyFeatures: [],
      FilterModel: false,
      ShowFilter: 'All',
      Loading: false,
      Submit: false,
      Color: [
        '#4184f3', '#7cb442', '#ff5722', '#ff8f00', '#aa46bb',
        '#25abc0', '#9d9c23', '#5b6abf', '#ef6191', '#10786a',
        '#c11c5a', '#fa937f', '#34c0ff'
      ],
      ViewTypeOptions: [
        {
          value: 'Monthly',
          label: '每月'
        },
        {
          value: 'Daily',
          label: '每日'
        }
      ],
      ViewType: 'Monthly',
      noItemsView: {
        noResults: this.$t('Organization/Member.NoResults'),
        noItems: this.$t('Organization/Member.NoItems')
      },
      ChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      },
      Order: 'TotalOrderItems_desc'
    }
  },
  computed: {
    ClassOptions() {
      return Object.keys(this.$t('Organization.ClassOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Organization.ClassOptions')[option]
        }
      })
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    SortOption() {
      return Object.keys(this.$t('Report/Member.TagsSortType')).map(option => {
        return {
          value: option,
          label: this.$t('Report/Member.TagsSortType')[option]
        }
      })
    },
    Field() {
      const Default = [
        {key: 'CreateTime', label: this.$t('Report/Member.RegisterTime')},
      ]
      if (this.MemberLevelList.length > 1) {
        this.MemberLevelList.forEach(item => {
          Default.push({
            key: item.ID.toString(),
            label: item.Name
          })
        })
      }
      return Default
    },
    ChartLabels () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.DateRange.sort((a, b) => this.$dayjs(a).unix() - this.$dayjs(b).unix()).map(item => this.$dayjs(item).format('MM/DD') || '--')
    },
    ChartDatasets () {
      if (this.Reporting.length > 0 ) {
        const SortReporting = this.Reporting.map(item => item).sort((a, b) => this.$dayjs(a._id).unix() - this.$dayjs(b._id).unix())
        const Data = []
        this.MemberLevelList.forEach((level, index) => {
          const data = []
          this.DateRange.forEach(date => {
            const SortData = SortReporting.find(item => {
              return this.ViewType === 'Monthly' ? this.$dayjs(item._id).format('YYYY-MM') === date : item._id === date
            })
            if (SortData) {
              data.push(SortData[level.ID])
            } else {
              data.push(0)
            }
          })
          Data.push({
            label: level.Name,
            borderColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
            fill: false,
            // data: SortReporting.map(item => item[level.ID])
            data
          })
        })
        return Data
      }
      return []
    },
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Organization ? (PermissionSession.Features.Organization.Member || []) : []
    this.NotifyFeatures = PermissionSession.Features.Notify || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const Executes = [
        this.GetAllTags(),
        this.GetList()
      ]
      if (this.Features.includes('MemberLevel')) {
        Executes.push(
          this.GetMemberLevelList()
        )
      }
      return Promise.all(Executes).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList(ViewType = 'Monthly') {
      this.ViewType = ViewType
      this.ChooseUsers = []
      this.FilterModel = false
      this.Loading = true
      const data = {
        Interval: ViewType
      }
      const FilterData = ['StartTime', 'EndTime']
      FilterData.forEach((field) => {
        switch (typeof this.SearchFilter[field]) {
          case 'object':
            switch (field) {
              case 'StartTime':
                data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                break
              case 'EndTime':
                data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                break
            }
            break
          default:
            if (this.SearchFilter[field]) {
              switch (field) {
                case 'StartTime':
                  data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
                default:
                  data[field] = this.SearchFilter[field]
                  break
              }
            }
            break
        }
      })
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/member/level/daily',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
          ...data
        },
        headers: {
          'id-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(({data}) => {
        this.Reporting = data.Data.map(item => {
          const Result = {
            _id: item.Date,
          }
          item.Data.forEach(level => {
            Result[level._id] = level.MemberCount
          })
          return Result
        })
        this.DateRange = data.DateRange
        this.Loading = false
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ResetState(closeCreateUserModel = false) {
      if (closeCreateUserModel) {
        this.CreateUserModel = false
      }
      this.Submit = false
      this.AlertModel = false
      this.ChooseUsers = []
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.Pagination = {
        Page: 1,
        PerPage: 30,
        TotalItems: 0,
        TotalPages: 0
      }
      this.SearchFilter = {
        Email: [],
        Class: '',
        Name: '',
        Country: '',
        Mobile: '',
        Tags: [],
        TotalRealAmountMin: '',
        TotalRealAmountMax: '',
        WalletBonusMin: '',
        WalletBonusMax: '',
        BirthdayMonth: '',
        Level: [],
        StartTime: this.$dayjs().subtract(2, 'month').startOf('month').unix() * 1000,
        EndTime: this.$dayjs().endOf('month').unix() * 1000,
        TimeRange: {
          start: this.$dayjs().subtract(2, 'month').startOf('month').unix() * 1000,
          end: this.$dayjs().endOf('month').unix() * 1000
        },
        City: '',
        Area: '',
        Address: ''
      }
      this.ChooseIndex = []
      this.Init()
    },
    GetMemberLevelList() {
      this.Loading = true
      this.MemberLevelList = [
        {
          ID: 0,
          Name: '一般會員',
          ShopDiscount: 100,
          GoldDiscount: 0,
          FullAmount: 0,
          NextFullAmount: '--',
          Status: true
        }
      ]
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/list',
        method: 'post'
      }).then(({data}) => {
        this.Loading = false
        Object.values(data).forEach((item) => {
          this.MemberLevelList.push(item)
        })
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    GetAllTags() {
      this.Submit = true
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/member/tags',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
        },
        headers: {
          'id-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(({data}) => {
        this.Loading = false
        this.Submit = false
        this.Tags = data.Data
      }).catch((error) => {
        this.Loading = false
        this.Submit = false
        throw error
      })
    },
    Print() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        Interval: this.ViewType
      }
      const fieldName = {}
      this.MemberLevelList.forEach(item => {
        fieldName[item.ID] = item.Name
      })
      const FilterData = ['StartTime', 'EndTime']
      FilterData.forEach((field) => {
        switch (typeof this.SearchFilter[field]) {
          case 'object':
            switch (field) {
              case 'StartTime':
                Data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                break
              case 'EndTime':
                Data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                break
            }
            break
          default:
            if (this.SearchFilter[field]) {
              switch (field) {
                case 'StartTime':
                  Data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  Data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
                default:
                  Data[field] = this.SearchFilter[field]
                  break
              }
            }
            break
        }
      })
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/member/level/daily',
          Data,
          type: 'levelDailyStatistics',
          fieldName
        }
      }).then((response) => {
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `會員等級變化趨勢-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    }
  }
}
</script>
